import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
// import arLogo from '../../assets/img/ar-logo.svg';
import arrow from '../../assets/img/modelCreation/arrow.svg';
import FreeTrialButton from '../common/freeTrialButton';
import { getCustomArBannerProduct } from '../../utils/product';

export default function CustomArBanner() {
  const history = useHistory();

  const handleOnClickProduct = (e) => {
    e.preventDefault();
    const newProduct = { ...getCustomArBannerProduct() };
    history.push(`/product/${newProduct.id}`);
  };

  return (
    <section className="container flex flex-col items-center py-10">
      <h1 className="font-semibold text-center text-yellow-400 text-6xl">
        <FormattedMessage id="MAIN_TITLE" />
      </h1>
      <p className="mt-3 text-center text-gray-400 text-3xl">
        <FormattedMessage id="MAIN_SUBTITLE" />
      </p>
      <div
        className="cursor-pointer mt-6 relative rounded-lg border-gray-400 border-2 flex items-center justify-center"
        onClick={handleOnClickProduct}
        style={{ width: '360px', height: '360px' }}>
        {/*<img*/}
        {/*  className="z-20 w-16 absolute right-2 top-2 shadow rounded-full"*/}
        {/*  src={arLogo}*/}
        {/*  alt="AR Supported"*/}
        {/*/>*/}
        {/*<img*/}
        {/*  src={getCustomArBannerProduct().URLWebp}*/}
        {/*  style={{ width: '315px', height: '315px' }}*/}
        {/*  alt="AR Char"*/}
        {/*/>*/}
        <iframe
          src="https://3d.goyuge.com/65ac17787e85dbf1c1783384"
          height="100%"
          width="100%"
          title="Iframe Example"
        />
      </div>
      <a
        href={'#'}
        onClick={handleOnClickProduct}
        className="mt-4 text flex flex-row">
        <FormattedMessage id="MAIN_CTA" />
        <div>
          <img className="ml-3" src={arrow} alt="" />
        </div>
      </a>

      <p className="d-none d-sm-none d-md-block d-lg-block d-xl-block text-xl mt-12 md:w-3/4 text-center text-gray-800 font-semibold md:text-4xl">
        <FormattedMessage id="MAIN_DESCRIPTION" values={{ br: <span /> }} />
      </p>
      <p className="text-left d-block d-sm-block d-md-none d-lg-none d-xl-none text-xl mt-10 md:w-3/4 text-gray-800 font-semibold md:text-4xl">
        <FormattedMessage id="MAIN_DESCRIPTION" values={{ br: <span /> }} />
      </p>
      <FreeTrialButton
        classes="free-trial-account text-white mt-8"
        text={<FormattedMessage id="FREE_TRIAL_ACCOUNT" />}
      />
    </section>
  );
}
