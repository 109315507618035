import {
  HIDE_LOADING,
  SHOW_LOADING,
  SET_EMAIL_SENT,
  SET_ERROR_TRIAL_MESSAGE,
  RECEIVED_SHOPS,
  CLEAR_SHOPS,
  RECEIVED_CATEGORIES,
  EMAIL_CONFIRMED,
  SHOW_LOGIN_MODAL,
  SET_USER,
  SHOW_CART,
} from './ActionTypes';
import { recommendedCategory } from '../services/categoryService';

const initialState = {
  loading: false,
  loadingText: '',
  emailSent: false,
  errorTrialMessage: null,
  user: null,
  emailConfirmed: false,
  showLoginModal: false,
  showCart: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HIDE_LOADING:
      return {
        ...state,
        loading: false,
        loadingText: '',
      };

    case SHOW_LOADING:
      return {
        ...state,
        loading: true,
        loadingText: action.payload,
      };

    case SET_EMAIL_SENT:
      return {
        ...state,
        emailSent: action.payload,
      };

    case EMAIL_CONFIRMED:
      return {
        ...state,
        emailConfirmed: action.payload,
      };

    case SET_ERROR_TRIAL_MESSAGE:
      return {
        ...state,
        errorTrialMessage: action.payload,
      };

    case SHOW_LOGIN_MODAL:
      return {
        ...state,
        showLoginModal: action.payload,
      };

    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };

    case SHOW_CART:
      return {
        ...state,
        showCart: action.payload,
      };

    default:
      return state;
  }
};

export const shopReducer = (
  state = {
    loading: false,
    loadingText: '',
    merchants: [],
    paginationParams: null,
    categories: [recommendedCategory],
    hasMore: true,
  },
  action,
) => {
  switch (action.type) {
    case HIDE_LOADING:
      return {
        ...state,
        loading: false,
        loadingText: '',
      };
    case SHOW_LOADING:
      return {
        ...state,
        loading: true,
        loadingText: action.payload,
      };
    case CLEAR_SHOPS:
      return { ...state, merchants: [] };
    case RECEIVED_SHOPS:
      if (action.payload.append) {
        const merchants = [...state.merchants];
        return {
          ...state,
          loading: false,
          merchants: merchants.concat(action.payload.shops.merchants),
          paginationParams: action.payload.shops.paginationParams,
          hasMore: action.payload.shops.merchants < 9,
        };
      }

      return {
        ...state,
        loading: false,
        merchants: action.payload.shops.merchants,
        paginationParams: action.payload.shops.paginationParams,
        hasMore: action.payload.shops.merchants < 9,
      };

    case RECEIVED_CATEGORIES:
      // let cat = [state.se]
      return { ...state, categories: [recommendedCategory, ...action.payload] };
    default:
      return state;
  }
};

export default reducer;
