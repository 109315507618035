import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import MerchantPreview from './merchantPreview';

const MerchantGrid = ({
  merchants,
  isEmptySearch,
  clssName = 'mb-16',
  mainPage = false,
}) => {
  return (
    <div className={mainPage ? 'w-full md:w-9/12' : 'w-full'}>
      <div
        className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 place-items-center ${clssName}`}>
        {merchants.map((merchant, i) => (
          <MerchantPreview key={i} merchant={merchant} />
        ))}
      </div>
      {isEmptySearch && (
        <h3 className="empty-search">
          <FormattedMessage id="EMPTY_SEARCH" />
        </h3>
      )}
    </div>
  );
};

export default memo(MerchantGrid);
