import { createStore, combineReducers, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

// Local
import trialModeReducer from './Reducer';
import { shopReducer } from './Reducer';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  trial: trialModeReducer,
  shop: shopReducer,
});
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);
// const store = createStore(rootReducer,applyMiddleware(thunkMiddleware));
sagaMiddleware.run(rootSaga);
export default store;
