import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import SocialNetwork from './common/socialNetwork';
import Badges from './common/badges';
import LegalNote from './common/legalNote';

const Footer = ({ selectedLanguage }) => {
  return (
    <footer className="pt-12 mt-0">
      <h2 className="text-blue-500 pb-10 font-bold">
        <FormattedMessage id="DOWNLOAD_THE_APP" />
      </h2>
      <Badges />
      <SocialNetwork classes="footer-links mb-10" />
      <LegalNote locale={selectedLanguage} />
    </footer>
  );
};

export default memo(Footer);
