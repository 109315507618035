const regions = [
  {
    code: 'ES',
    title: 'Barcelona',
    domain: 'yuge.es',
    url: 'http://www.yuge.es',
  },
  {
    code: 'NL',
    title: 'Amsterdam',
    domain: 'yuge.nl',
    url: 'http://www.yuge.nl',
  },
  {
    code: 'US',
    title: 'New York | San Francisco',
    domain: 'goyuge.com',
    url: 'http://www.goyuge.com',
  },
];

function getRegionFromDomain() {
  const domain = window.location.hostname;
  return regions.find((region) => domain.endsWith(region.domain)) || regions[0];
}

function getURLFromRegion(countryCode) {
  const region = regions.find((reg) => countryCode === reg.code) || regions[0];
  return region.url;
}

export default {
  data: getRegionFromDomain(),
  code: getRegionFromDomain().code,
  socialMediaUsername: getRegionFromDomain().socialMediaUsername,
  urlFor: getURLFromRegion,
};
