import React, { memo } from 'react';
import PropType from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';
import { FormattedMessage } from 'react-intl';
import arrowBlue from '../../assets/img/modelCreation/arrow-blue.svg';

const TextImageSection = ({
  src,
  inverted,
  children,
  link,
  clssName = 'md:items-center',
}) => {
  const fadeInEffect = !inverted
    ? 'animate__fadeInLeft'
    : 'animate__fadeInRight';
  return (
    <div
      className={`relative flex flex-col ${
        inverted ? 'md:flex-row-reverse' : 'md:flex-row'
      } w-full md:justify-between ${clssName} my-12 md:my-20`}>
      <div className="md:w-6/12">
        <ScrollAnimation animateIn={fadeInEffect} animateOnce>
          <img className="px-6 md:px-0 w-100 rounded" src={src} alt="Imaged" />
        </ScrollAnimation>
        {link && (
          <a
            href={link}
            target="_blank"
            className="relative md:absolute w-full mb-2 mt-4 flex flex-row pr-6 md:pr-0 justify-center md:justify-start text-blue-400 font-bold"
            style={{ fontSize: '18px' }}>
            <FormattedMessage id="VIEW_EXAMPLE" />
            <div>
              <img
                className="ml-3 mr-0 md:mr-4"
                src={arrowBlue}
                alt="view example"
              />
            </div>
          </a>
        )}
      </div>
      <div className="mt-6 md:w-5/12">{children}</div>
    </div>
  );
};

TextImageSection.propTypes = {
  src: PropType.string.isRequired,
  inverted: PropType.bool,
  children: PropType.node,
  clssName: PropType.string,
};

export default memo(TextImageSection);
