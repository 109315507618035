import React, { useState } from 'react';
import { Button, Row, Col, Media, Accordion } from 'react-bootstrap';

const styles = {
  mediaItem: {
    borderTop: '1px solid #dc3545',
  },
};

const ItemDetails = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Accordion>
      <Accordion.Toggle
        as={Button}
        variant="link"
        eventKey="products-cart-details-event"
        onClick={() => setCollapsed(!collapsed)}
        style={{ textDecoration: 'none', color: '#dc3545' }}>
        {collapsed === false ? 'See' : 'Hide'} item details{' '}
        {collapsed === false ? '+' : '-'}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="products-cart-details-event">
        <>
          <Media style={styles.mediaItem}>
            <img
              width={100}
              height={100}
              className="align-self-center mr-3"
              src="https://i5.walmartimages.com/asr/e73e1252-642c-4473-93ea-fd3b564a7027_1.3e81ea58fa3042452fe185129a4a865f.jpeg?odnWidth=undefined&odnHeight=undefined&odnBg=ffffff"
              alt="Generic placeholder"
            />
            <Media.Body>
              <p />
              <Row>
                <Col xs={6}>1 piece</Col>
                <Col xs={6} style={{ textAlign: 'right' }}>
                  <strong>$48.99</strong>
                </Col>
              </Row>

              <Row>
                <Col xs={6} />
                <Col xs={6} style={{ textAlign: 'right' }}>
                  <Button variant="danger" size="sm">
                    Delete
                  </Button>
                </Col>
              </Row>
            </Media.Body>
          </Media>
          <Media style={styles.mediaItem}>
            <img
              width={100}
              height={100}
              className="align-self-center mr-3"
              src=" https://images-na.ssl-images-amazon.com/images/I/81lGKc7oDGL._SX425_.jpg"
              alt="Generic placeholder"
            />
            <Media.Body>
              <p />
              <Row>
                <Col xs={6}>1 piece</Col>
                <Col xs={6} style={{ textAlign: 'right' }}>
                  <strong>$299.99</strong>
                </Col>
              </Row>

              <Row>
                <Col xs={6} />
                <Col xs={6} style={{ textAlign: 'right' }}>
                  <Button variant="danger" size="sm">
                    Delete
                  </Button>
                </Col>
              </Row>
            </Media.Body>
          </Media>
        </>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default ItemDetails;
