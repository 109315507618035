import React, {
  memo,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import getProduct from '../services/productByIdService';
import ProductPreview3D from './product/productPreview3D';
import { goToAR } from '../utils/augmentedReality';
import init from '../utils/easel';
import useQuery from '../hooks/useQuery';
import Logo from './logo';
import { LIVE } from '../api/yugeAPI2';

const ProductPage = () => {
  const [isMobile2, setIsMobile2] = useState(
    window.matchMedia('(max-width: 768px)').matches,
  );
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    const handleMediaQueryChange = (mq) => {
      setIsMobile2(mq.matches);
    };

    mediaQuery.addListener(handleMediaQueryChange);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const [product, setProduct] = useState(null);
  const width =
    screen.height > screen.width ? screen.height / 4 : screen.width / 4;
  const height =
    screen.height > screen.width ? screen.height / 4 : screen.width / 4;
  const { productId } = useParams();
  const query = useQuery();
  const [isMobile, setIsMobile] = useState({
    isAndroid: false,
    isIOS: false,
  });
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const [render, setRender] = useState(false);
  const [processingAssets, setProcessingAssets] = useState(false);
  const [isActive3dModel, setIsActive3dModel] = useState(false);

  const initAnimation = (error) => {
    const img = imageRef.current;
    const canvas = canvasRef.current;

    if (!error && img?.complete && canvas) {
      init(canvas, img);
    } else {
      console.log('Image extraction load failure!');
    }
  };

  const getFilename = (p) => {
    const activeExtraction = p?.extractions?.filter(
      (i) => i.active === true,
    )[0];
    return activeExtraction.filename;
  };

  const redirectToArView = () => {
    const newProduct = { ...product };
    const activeExtraction = newProduct?.extractions?.filter(
      (i) => i.active === true,
    )[0];

    // If there is an active extraction then will use 2d glb and usdz
    if (activeExtraction) {
      if (!activeExtraction.filename) {
        setProcessingAssets(true);
        return;
      }
      console.log('Active extraction found');
      const glbFiles = newProduct?.glbFiles?.filter(
        (i) => i.ext_filename === activeExtraction.filename,
      );
      const usdzFiles = newProduct?.usdzFiles?.filter(
        (i) => i.ext_filename === activeExtraction.filename,
      );

      if (
        !glbFiles ||
        glbFiles.length === 0 ||
        !usdzFiles ||
        usdzFiles.length === 0
      ) {
        console.log('No extraction glb or usdz files found');
        setProcessingAssets(true);
        return;
      }

      newProduct.glbFiles = glbFiles;
      newProduct.usdzFiles = usdzFiles;
      newProduct.glbFilesUploaded = null;
      newProduct.usdzFilesUploaded = null;
    } else {
      // If there is no active extraction will try to get the active 3d models
      console.log('No extraction is active. Looking for 3d active models');
      const activeGLBModels = newProduct?.glbFilesUploaded?.filter((i) => {
        console.log('GLB model', i);
        return i.active === true;
      });
      const activeUSDZModels = newProduct?.usdzFilesUploaded?.filter(
        (i) => i.active === true,
      );

      if (/Android/i.test(navigator.userAgent)) {
        if (!activeGLBModels || activeGLBModels.length === 0) {
          console.log(
            'No active glb models found',
            newProduct,
            activeGLBModels,
          );
          setProcessingAssets(true);
          return;
        }
      } else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        if (!activeUSDZModels || activeUSDZModels.length === 0) {
          console.log(
            'No active usdz models found',
            newProduct,
            activeUSDZModels,
          );
          setProcessingAssets(true);
          return;
        }
      }

      newProduct.glbFiles = null;
      newProduct.usdzFiles = null;
      newProduct.glbFilesUploaded = activeGLBModels;
      newProduct.usdzFilesUploaded = activeUSDZModels;
    }

    console.log('Go to AR with product object edited', newProduct);
    goToAR({
      product: newProduct,
      ARNotSupportedFallback: () => setRender(true),
    });
  };

  function populateProduct() {
    getProduct(productId)
      .then(({ data: productData }) => {
        if (productData.extractions.length > 0) {
          const activeExtractions = productData.extractions.filter(
            (i) => i.active === true,
          );
          if (!activeExtractions.length) {
            if (productData.images?.length) {
              productData.featuredImage = productData.extractions[0].URL;
            }
          } else {
            productData.featuredImage = activeExtractions[0].URL;
          }
        } else if (productData.images?.length) {
          productData.featuredImage = productData.images[0].URLWebp
            ? productData.images[0].URLWebp
            : productData.images[0].URL;
        }
        if (productData.glbFilesUploaded?.length) {
          productData.glbFilesUploaded.forEach((glb) => {
            if (glb.active) setIsActive3dModel(true);
          });
        }
        setProduct(productData);
      })
      .catch((error) => {
        console.log('Product not found', error);
        setProduct(false);
      });
  }

  useLayoutEffect(() => {
    if (product) {
      if (query?.get('noAR')) {
        setRender(true);
        return;
      }
      redirectToArView();
    }
  }, [product]);

  useLayoutEffect(() => {
    if (productId) populateProduct();
  }, [productId]);

  useLayoutEffect(() => {
    if (/Android/i.test(navigator.userAgent)) {
      setIsMobile({
        isIOS: false,
        isAndroid: true,
      });
    }
    if (/iPhone|iPad/i.test(navigator.userAgent)) {
      setIsMobile({
        isAndroid: false,
        isIOS: true,
      });
    }
  }, []);

  if (product === false) {
    return (
      <div className="min-h-screen flex flex-column items-center justify-center">
        <div className="alert warning flex items-center">
          <svg
            style={{ fill: 'rgb(239, 83, 80)', width: '1rem', height: '1rem' }}
            className="mr-2 MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1cw4hi4"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="SuccessOutlinedIcon">
            <path d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
          </svg>
          Product not found.
        </div>
      </div>
    );
  }

  if (processingAssets) {
    return (
      <div className="min-h-screen flex flex-column items-center justify-center">
        <div className="alert warning flex items-center">
          <svg
            style={{ fill: 'rgb(239, 83, 80)', width: '1rem', height: '1rem' }}
            className="mr-2 MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1cw4hi4"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="SuccessOutlinedIcon">
            <path d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
          </svg>
          We are processing the AR files. Please try again in a couple of
          minutes.
        </div>
      </div>
    );
  }

  if (!render) {
    return null;
  }

  const RenderProductPreview3D = () => (
    <ProductPreview3D
      src={product.usdzFiles[0].URL}
      arImg={product.glbFiles[0].URL}
      w={width}
      h={height}
    />
  );

  return (
    <div
      className="flex flex-col items-center justify-center py-10"
      style={{ minHeight: '100vh' }}>
      <div className="w-full p-6 py-10 flex justify-center items-center flex-col-reverse md:flex-row gap-14">
        <div
          className="flex items-center justify-center"
          style={{
            width: isMobile2 ? '100%' : '50%',
            // minWidth: '320px',
            height: height,
            minHeight: '600px',
          }}>
          <img
            crossOrigin="anonymous"
            ref={imageRef}
            onLoad={() => initAnimation(false)}
            onError={() => initAnimation(true)}
            src={product.featuredImage}
            style={{ display: 'none' }}
            alt={product.title || 'AR Extraction Image'}
          />
          {product && !isActive3dModel && (
            <canvas
              id="canvas"
              ref={canvasRef}
              width={width}
              height={height}
              style={{
                width: width + 50,
                height: height + 50,
                marginBottom: 50,
                backgroundColor: 'white',
                borderRadius: '20px',
              }}
            />
          )}
          {isActive3dModel && (
            <div
              className="mt-4 relative flex items-center justify-center"
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                borderRadius: '20px',
              }}>
              <iframe
                src={`https://3d.goyuge.com/${productId}?noAR=1&devMode=${
                  LIVE ? 0 : 1
                }`}
                height="100%"
                width="100%"
                style={{ borderRadius: '20px' }}
              />
            </div>
          )}
        </div>
        <div
          className="mt-4 flex items-center justify-between flex-col py-12" /* justify-center  */
          style={{
            width: width * 0.85,
            minWidth: '405px',
            height: height,
            minHeight: '600px',
            backgroundColor: 'white',
            borderRadius: '20px',
          }}>
          <QRCode
            size={width / 2}
            value={window.location.href}
            viewBox="0 0 256 256"
            bgColor="rgba(1, 1, 1, 0)"
          />
          <p
            className="mt-6 text-xl font-semibold px-10"
            style={{ textAlign: 'center' }}>
            <FormattedMessage
              id="SCAN_QR"
              values={{
                br: <br />,
                AR: <span className=" font-bold">Augmented Reality</span>,
                RA: <span className=" font-bold">Realidad Aumentada</span>,
              }}
            />
          </p>
          <span style={{ marginTop: 30 }}>Augmented Reality powered by</span>
          <a
            className="ml-1 text-blue-500"
            href={window.location.origin}
            style={{ width: '100px' }}>
            <Logo />
          </a>
        </div>
      </div>
    </div>
  );
};
export default memo(ProductPage);
