/**
 * Go to augmented reality
 * @param product, the product object
 * @param ARNotSupportedFallback, if AR is not supported this function will be called
 */
export const goToAR = ({
  product,
  ARNotSupportedFallback,
  withCustomBanner = true,
  arUrl = null,
}) => {
  const {
    price: priceInCents,
    currency,
    title: fullTitle,
    glbFiles,
    glbFilesUploaded,
    usdzFiles,
    usdzFilesUploaded,
    externalBuyUrl,
    externalBuyEnabled,
    id,
  } = product;
  const anchor = document.getElementById('ar-link');

  const title = fullTitle ? `${fullTitle.slice(0, 37)}...` : null;

  //Check for iOS and Safari compatibility
  const existingUsdzFile = usdzFilesUploaded?.length
    ? usdzFilesUploaded
    : usdzFiles;
  if (anchor.relList.supports('ar') && (arUrl || existingUsdzFile?.length)) {
    let customBannerTitle = '-';
    let customBannerPrice = '-';
    let customBannerActionText = 'BUY';
    let customDomain = 'AR by yuge.nl';

    let usdzUrl = arUrl
      ? `${arUrl}#allowsContentScaling=1`
      : `${
          existingUsdzFile[existingUsdzFile.length - 1].URL
        }#allowsContentScaling=1`;
    if (withCustomBanner) {
      if (title && title.trim() !== '') {
        const trimmedTitle = title.trim();
        customBannerTitle = trimmedTitle.replaceAll(' ', '__SPACE__');
      }
      if (
        priceInCents &&
        priceInCents.trim() !== '' &&
        currency &&
        currency.trim() !== ''
      ) {
        const priceInCurrency = priceInCents.trim();
        if (currency.trim() === '€') {
          customBannerPrice = `_EUR_${priceInCurrency}`;
        } else {
          customBannerPrice = `${currency.trim()}${priceInCurrency}`;
        }
      }

      if (
        externalBuyEnabled &&
        externalBuyUrl &&
        externalBuyUrl.trim() !== ''
      ) {
        customDomain = externalBuyUrl.split('/')[2];
      }

      const trimmedDomain = customDomain.trim();
      customDomain = trimmedDomain.replaceAll(' ', '__SPACE__');
      usdzUrl = arUrl
        ? `${arUrl}#allowsContentScaling=1&custom=https://dev-banner.goyuge.com/${customBannerTitle}/${customBannerPrice}/${customBannerActionText}/${customDomain}`
        : `${
            existingUsdzFile[existingUsdzFile.length - 1].URL
          }#allowsContentScaling=1&custom=https://dev-banner.goyuge.com/${customBannerTitle}/${customBannerPrice}/${customBannerActionText}/${customDomain}`;
      usdzUrl = `${usdzUrl}&customHeight=medium`; // height of the custom banner (small, medium, large)
    } else {
      if (title && title.trim() !== '') {
        const trimmedTitle = title.trim();
        usdzUrl = `${usdzUrl}&checkoutTitle=${trimmedTitle}`; // title to use in case "custom banner" url fails.
      } else {
        usdzUrl = `${usdzUrl}&checkoutTitle=-`; // title to use in case "custom banner" url fails.
      }

      if (
        priceInCents &&
        priceInCents.trim() !== '' &&
        currency &&
        currency.trim() !== ''
      ) {
        const priceInCurrency = priceInCents.trim();
        usdzUrl = `${usdzUrl}&price=${currency.trim()}${priceInCurrency}`; // price and currency to use in case "custom banner" url fails.
      }

      usdzUrl = `${usdzUrl}&callToAction=${customBannerActionText}`; // Text to show for blue button in case "custom banner" url fails.
      usdzUrl = `${usdzUrl}&canonicalWebPageURL=${window.location.protocol}//${window.location.host}/product/${id}?noAR=1`; // The action will trigger link to product page without AR
    }

    anchor.setAttribute('href', usdzUrl);
    anchor.addEventListener(
      'message',
      function (event) {
        if (
          externalBuyEnabled &&
          externalBuyUrl &&
          externalBuyUrl.trim() !== ''
        ) {
          window.location.href = externalBuyUrl.trim().replaceAll(' ', '%20');
        } else if (typeof window !== 'undefined') {
          window.location.href = `${window.location.protocol}//${window.location.host}/product/${id}?noAR=1`;
        }
      },
      false,
    );

    anchor.click();
    return;
  }

  //Check for android and Google Chrome compatibility
  const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
  const existingGlbFile = glbFilesUploaded?.length
    ? glbFilesUploaded
    : glbFiles;
  if (
    /Android/i.test(navigator.userAgent) &&
    isChrome &&
    (arUrl || existingGlbFile?.length)
  ) {
    let glbURL = arUrl
      ? `intent://arvr.google.com/scene-viewer/1.0?file=${arUrl}`
      : `intent://arvr.google.com/scene-viewer/1.0?file=${
          existingGlbFile[existingGlbFile.length - 1].URL
        }`;
    glbURL = `${glbURL}&mode=ar_only`;

    // When set to true, users will be able to place the model on a vertical surface.
    glbURL = `${glbURL}&enable_vertical_placement=true`;

    const text = `title: ${title || ''}`;
    if (text && text.trim() !== '') {
      glbURL = `${glbURL}&title=${text.trim().replaceAll(' ', '%20')}`;
    } else {
      glbURL = `${glbURL}&title=-`;
    }

    const domainUrl = `${window.location.protocol}//${window.location.host}`;
    if (externalBuyEnabled && externalBuyUrl && externalBuyUrl.trim() !== '') {
      glbURL = `${glbURL}&link=${externalBuyUrl.trim().replaceAll(' ', '%20')}`;
    } else {
      glbURL = `${glbURL}&link=${domainUrl}/product/${id}?noAR=1`;
    }
    glbURL = `${glbURL}#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=${domainUrl};end;`;
    anchor.setAttribute('href', glbURL);
    anchor.click();
    return;
  }

  //No AR supported, then go to product details
  if (ARNotSupportedFallback) ARNotSupportedFallback();
};
