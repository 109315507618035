import React from 'react';
import undoneCircle from '../../assets/img/shoppingCart/undone_circle.svg';
import doneCircle from '../../assets/img/shoppingCart/done_circle.svg';
import warningTriangle from '../../assets/img/shoppingCart/warning_triangle.svg';
import infoCircle from '../../assets/img/shoppingCart/info_circle.svg';

export const cartSteps = {
  cartView: 0,
  shippingView: 1,
  paymentView: 2,
  reviewView: 3,
  finishView: 4,
};

export const cartStepsStates = {
  undone: 0,
  warning: 1,
  done: 2,
  editing: 3,
};

const styles = {
  cartStatesImg: {
    width: 40,
    height: 40,
  },
  line: {
    width: 100,
    height: 5,
    backgroundColor: '#666666',
    marginBottom: 25,
  },
  stepBox: {
    width: 100,
  },
};

const StepsCart = (props) => {
  const { step, state } = props;

  if (step === cartSteps.cartView) {
    return null;
  }

  return (
    <div className="flex justify-center items-center">
      <div
        className="flex flex-wrap  flex-col items-center"
        style={styles.stepBox}>
        {step !== cartSteps.shippingView && (
          <img src={doneCircle} alt="done" style={styles.cartStatesImg} />
        )}
        {step === cartSteps.shippingView &&
          state === cartStepsStates.warning && (
            <img
              src={warningTriangle}
              alt="warning"
              style={styles.cartStatesImg}
            />
          )}
        {step === cartSteps.shippingView &&
          state === cartStepsStates.editing && (
            <img src={infoCircle} alt="warning" style={styles.cartStatesImg} />
          )}
        <span style={{ color: '#666666' }}>Shipping</span>
      </div>
      <div
        style={{
          ...styles.line,
          backgroundColor:
            step !== cartSteps.shippingView ? '#FFB000' : '#666666',
        }}
      />

      <div
        className="flex flex-wrap  flex-col items-center"
        style={styles.stepBox}>
        {(step === cartSteps.reviewView || step === cartSteps.finishView) && (
          <img src={doneCircle} alt="done" style={styles.cartStatesImg} />
        )}
        {step === cartSteps.shippingView && (
          <img src={undoneCircle} alt="undone" style={styles.cartStatesImg} />
        )}
        {step === cartSteps.paymentView &&
          state === cartStepsStates.warning && (
            <img
              src={warningTriangle}
              alt="warning"
              style={styles.cartStatesImg}
            />
          )}
        {step === cartSteps.paymentView &&
          state === cartStepsStates.editing && (
            <img src={infoCircle} alt="warning" style={styles.cartStatesImg} />
          )}
        <span style={{ color: '#666666' }}>Payment</span>
      </div>
      <div
        style={{
          ...styles.line,
          backgroundColor:
            step === cartSteps.reviewView || step === cartSteps.finishView
              ? '#FFB000'
              : '#666666',
        }}
      />

      <div
        className="flex flex-wrap  flex-col items-center"
        style={styles.stepBox}>
        {step === cartSteps.finishView && (
          <img src={doneCircle} alt="done" style={styles.cartStatesImg} />
        )}
        {step !== cartSteps.finishView && step !== cartSteps.reviewView && (
          <img src={undoneCircle} alt="undone" style={styles.cartStatesImg} />
        )}
        {step === cartSteps.reviewView && state === cartStepsStates.warning && (
          <img
            src={warningTriangle}
            alt="warning"
            style={styles.cartStatesImg}
          />
        )}
        {step === cartSteps.reviewView && state === cartStepsStates.editing && (
          <img src={infoCircle} alt="warning" style={styles.cartStatesImg} />
        )}
        <span style={{ color: '#666666' }}>Review</span>
      </div>
      <div
        style={{
          ...styles.line,
          backgroundColor:
            step === cartSteps.finishView ? '#FFB000' : '#666666',
        }}
      />

      <div
        className="flex flex-wrap  flex-col items-center"
        style={styles.stepBox}>
        {state !== cartSteps.finishView && (
          <img src={undoneCircle} alt="undone" style={styles.cartStatesImg} />
        )}
        {state === cartSteps.finishView &&
          state === cartStepsStates.warning && (
            <img
              src={warningTriangle}
              alt="warning"
              style={styles.cartStatesImg}
            />
          )}
        {state === cartSteps.finishView && state === cartStepsStates.done && (
          <img src={doneCircle} alt="done" style={styles.cartStatesImg} />
        )}
        <span style={{ color: '#666666' }}>Finish</span>
      </div>
    </div>
  );
};

export default StepsCart;
