import {
  HIDE_LOADING,
  SET_EMAIL_SENT,
  SET_ERROR_TRIAL_MESSAGE,
  SHOW_LOADING,
  RECEIVED_SHOPS,
  CLEAR_SHOPS,
  RECEIVED_CATEGORIES,
  FETCH_CATEGORIES,
  CREATE_TRIAL_ACCOUNT,
  CREATE_ACCOUNT,
  LOGIN_BY_EMAIL,
  EMAIL_CONFIRMED,
  CONFIRM_EMAIL,
  SHOW_LOGIN_MODAL,
  SET_USER,
  SHOW_CART,
  GET_SHOPS,
  GET_BASKET,
} from './ActionTypes';

export const hideLoading = () => ({
  type: HIDE_LOADING,
});

export const showLoading = (loadingText) => ({
  type: SHOW_LOADING,
  payload: loadingText,
});

export const setEmailSent = (sent) => ({
  type: SET_EMAIL_SENT,
  payload: sent,
});

export const setErrorTrialMessage = (message) => ({
  type: SET_ERROR_TRIAL_MESSAGE,
  payload: message,
});

export const clearShops = () => ({ type: CLEAR_SHOPS });

export const createTrialAccount = (data) => ({
  type: CREATE_TRIAL_ACCOUNT,
  payload: data,
});

export const createAccount = (data) => ({
  type: CREATE_ACCOUNT,
  payload: data,
});

export const emailConfirmedAction = (confirmed) => ({
  type: EMAIL_CONFIRMED,
  payload: confirmed,
});

export const confirmEmail = (data) => ({ type: CONFIRM_EMAIL, payload: data });

export const login = (data) => ({ type: LOGIN_BY_EMAIL, payload: data });

export const showLoginModalAction = (show) => ({
  type: SHOW_LOGIN_MODAL,
  payload: show,
});

export const showCartAction = (show) => ({ type: SHOW_CART, payload: show });

export const receivedShops = (shops, append) => ({
  type: RECEIVED_SHOPS,
  payload: { shops, append },
});

export const getShops = (
  config,
  pageNumber,
  itemsPerPage,
  byCategory,
  withPriority,
  queryCompany,
  isWebShop,
) => ({
  type: GET_SHOPS,
  payload: {
    config,
    pageNumber,
    itemsPerPage,
    byCategory,
    withPriority,
    queryCompany,
    isWebShop,
  },
});

export const receivedCategories = (cat) => ({
  type: RECEIVED_CATEGORIES,
  payload: cat,
});

export const fetchCategories = (config) => ({
  type: FETCH_CATEGORIES,
  payload: config,
});

export const setUserAction = (user) => ({ type: SET_USER, payload: user });

export const getUserBasket = (user) => ({ type: GET_BASKET, payload: user });
