export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
export const SET_EMAIL_SENT = 'SET_EMAIL_SENT';
export const SET_ERROR_TRIAL_MESSAGE = 'SET_ERROR_TRIAL_MESSAGE';
export const RECEIVED_SHOPS = 'RECEIVED_SHOPS';
export const CLEAR_SHOPS = 'CLEAR_SHOPS';
export const RECEIVED_CATEGORIES = 'RECEIVED_CATEGORIES';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const CREATE_TRIAL_ACCOUNT = 'CREATE_TRIAL_ACCOUNT';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const EMAIL_CONFIRMED = 'EMAIL_CONFIRMED';
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const LOGIN_BY_EMAIL = 'LOGIN_BY_EMAIL';
export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL';
export const SHOW_CART = 'SHOW_CART';
export const SET_USER = 'SET_USER';
export const GET_SHOPS = 'GET_SHOPS';
export const GET_BASKET = 'GET_BASKET';
