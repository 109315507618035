import React, { memo } from 'react';
import PropTypes from 'prop-types';

const TextColumn = ({ title, description }) => {
  return (
    <div className="col-text scroll-reveal">
      <h3 style={{ textAlign: 'left' }}>{title}</h3>
      <p className="text-xl text-gray-800">{description}</p>
    </div>
  );
};

TextColumn.propTypes = {
  title: PropTypes.any,
  description: PropTypes.any,
};

export default memo(TextColumn);
