import http from './httpService';
import region from './regionService';
import { LIVE } from '../api/yugeAPI2';

const apiEndpoint = '/merchants/search';

export function getPaginatedMerchants(
  config,
  pageNumber,
  itemsPerPage,
  byCategory,
  withPriority,
  queryCompany,
) {
  const data = {
    live: LIVE,
    filter: {
      publish: true,
      publishOnWeb: true,
      withImages: true,
      sortPriorityCompanyName: true,
    },
    paginationByPageNumbers: {
      page: pageNumber,
      elements: itemsPerPage,
    },
  };

  if (byCategory !== '') {
    data.filter.category = byCategory;
  }

  if (withPriority) {
    data.filter.priority = true;
  }

  if (queryCompany !== '') {
    data.filter.queryCompany = queryCompany;
  }

  return http.post('/merchants/search-plus-pagination-params', data, config);
}

export function getMerchants(config, pageNumber, itemsPerPage) {
  const data = {
    live: LIVE,
    filter: {
      publish: true,
      publishOnWeb: true,
      withImages: true,
      sortPriorityCompanyName: true,
    },
    paginationByPageNumbers: {
      page: pageNumber,
      elements: itemsPerPage,
    },
  };
  return http.post(apiEndpoint, data, config);
}

export function getMerchant(slug, config) {
  const data = {
    live: LIVE,
    filter: {
      slug,
    },
  };
  return http.post(apiEndpoint, data, config);
}

export function getSimilarMerchants(category, merchantId, config) {
  const data = {
    live: LIVE,
    filter: {
      country: region.code,
      publish: true,
      publishOnWeb: true,
      withoutMerchant: merchantId,
    },
  };
  if (category) {
    data.filter.category = category;
  } else {
    data.filter.withPriority = true;
  }
  return http.post(apiEndpoint, data, config);
}

export function getPrioritizedMerchants(limit, config) {
  const data = {
    live: LIVE,
    filter: {
      withPriority: true,
    },
    pagination: {
      quantity: Number(limit),
    },
  };
  return http.post(apiEndpoint, data, config);
}
