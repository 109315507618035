import React from 'react';
import { FormattedMessage } from 'react-intl';
import Block from '../technology/block';
import FAQSItem from './faqsItem';

const totalQA = 10;

export default function FAQSContainer() {
  return (
    <div className="flex flex-col items-center justify-between" id="faqs">
      <div className="w-full md:w-auto">
        <Block
          title={<FormattedMessage id="FAQS" />}
          title2=""
          subtitle=""
          margin="m-16">
          <p className="text-xl mb-24 text-center text-gray-800 font-semibold md:text-4xl">
            <FormattedMessage id="FAQS_DESC" />
          </p>
        </Block>
        <div className="flex flex-col items-center">
          {[...Array(totalQA)].map((e, i) => (
            <FAQSItem question={`Q${i + 1}`} answer={`A${i + 1}`} />
          ))}
        </div>
      </div>
    </div>
  );
}
