import React, { memo } from 'react';
import Menu from './menu';
import SocialNetwork from './socialNetwork';

const NavBarDrawer = ({ classes, menuItems, state, onClickItem }) => {
  const menuItemsCopy = [...menuItems];
  menuItemsCopy.push({
    href: '/',
    text: 'CONTACT_PAGE',
    external: false,
    action: 'signup',
  });
  return (
    <div id="drawer" className={`${classes} ${state}`}>
      <ul>
        <Menu
          menuItems={menuItemsCopy}
          asListItem
          onClickItem={onClickItem}
          showContactButton={false}
        />
        <li>
          <SocialNetwork classes={`footer-links  ${classes}`} />
        </li>
      </ul>
    </div>
  );
};

export default memo(NavBarDrawer);
