import React from 'react';
import { Row, Col } from 'react-bootstrap';

const EstimatedTotal = () => {
  return (
    <Row>
      <Col xs={6}>
        <h3>Total:</h3>
      </Col>
      <Col xs={6} style={{ textAlign: 'right' }}>
        <h3>$100</h3>
      </Col>
    </Row>
  );
};

export default EstimatedTotal;
