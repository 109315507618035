import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Chair3 from '../../assets/img/cam/barcelona2.glb';
import Chair3iOS from '../../assets/img/cam/barcelona2.usdz';
import TextColumn from './textColumn';
import ProductPreview3D from '../product/productPreview3D';
import arrow from '../../assets/img/modelCreation/arrow.svg';

const product = {
  productId: 'chair2',
  usdzFiles: [
    {
      URL: Chair3,
    },
  ],
  glbFiles: [
    {
      URL: Chair3iOS,
    },
  ],
};

const ImageColumns = () => {
  const w = '250px';
  const h = '250px';

  return (
    <div className="container">
      <div className="flex flex-col w-full md:flex-row mt-10 justify-evenly items-center">
        <div className="md:w-1/3 text-left">
          <TextColumn
            title={<FormattedMessage id="INDEX_1_BLOCK_0_HEADER" />}
            description={<FormattedMessage id="INDEX_0_BLOCK_1_TEXT" />}
          />
        </div>
        <div className="md:w-1/3 w-full md:p-0 flex flex-col items-center">
          <div
            className="border rounded-xl flex items-center justify-center w-100"
            style={{ aspectRatio: '1/1' }}>
            <ProductPreview3D
              src={product.usdzFiles[0].URL}
              arImg={product.glbFiles[0].URL}
              w={w}
              h={h}
            />
          </div>
          <a
            rel="ar"
            href={'https://yuge.nl/product/655b8ec716dd17a2e126e8c3'}
            className="mt-4 text flex flex-row">
            <FormattedMessage id="MAIN_CTA" />
            <div>
              <img className="ml-3" src={arrow} alt="" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

ImageColumns.propTypes = {
  productPage: PropTypes.string,
};

export default memo(ImageColumns);
