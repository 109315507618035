import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function Loading({ loading }) {
  if (!loading) return <></>;
  return (
    <div className="centered mt-4">
      <Spinner animation="border" role="status" className="text-gray-400" />
    </div>
  );
}
