import PropType from 'prop-types';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PlanFeatures from './planFeatures';
import PricingCard from './pricingCard';

const plans = [
  {
    name: 'P_1_NAME',
    priceMonth: 20,
    priceYear: 200,
    description: 'P_1_DESC',
    featured: false,
    freeTrial: true,
    features: [
      {
        name: 'PF_1_1',
        enabled: false,
      },
      {
        name: 'PF_1_2',
        enabled: false,
      },
      {
        name: 'PF_1_3',
        enabled: false,
      },
      {
        name: 'PF_1_4',
        enabled: true,
        onclick: 'https://itunes.apple.com/app/yuge/id1046505084',
        style:
          'border-bottom border-dark a-blue-500 cursor-pointer text-decoration-none',
      },
      {
        name: 'PF_1_5',
        enabled: true,
      },
      {
        name: 'PF_1_6',
        enabled: true,
      },
      {
        name: 'PF_1_7',
        enabled: true,
      },
    ],
  },
  {
    name: 'P_2_NAME',
    priceMonth: 150,
    priceYear: 1500,
    description: 'P_2_DESC',
    featured: true,
    freeTrial: true,
    features: [
      {
        name: 'PF_1_1',
        enabled: true,
      },
      {
        name: 'PF_2_3',
        enabled: true,
      },
      {
        name: 'PF_1_3',
        enabled: true,
      },
      {
        name: 'PF_1_4',
        enabled: true,
      },
      {
        name: 'PF_2_1',
        enabled: true,
      },
      {
        name: 'PF_1_6',
        enabled: true,
        onclick:
          'https://apps.apple.com/nl/app/yuge-merchant/id6443528979?l=en',
        style:
          'border-bottom border-dark a-blue-500 cursor-pointer text-decoration-none',
      },
      {
        name: 'PF_2_2',
        enabled: true,
      },
    ],
    // features: ['PF_2_1', 'PF_2_2', 'PF_2_3', 'PF_2_4', 'PF_2_5', 'PF_2_6'],
  },
  {
    name: 'P_3_NAME',
    priceMonth: '',
    priceYear: '',
    description: 'P_3_DESC',
    featured: false,
    freeTrial: false,
    features: [
      {
        name: 'PF_3_1',
        enabled: true,
      },
      {
        name: 'PF_3_2',
        enabled: true,
      },
      {
        name: 'PF_3_3',
        enabled: true,
      },
    ],
    // features: ['PF_3_1', 'PF_3_2', 'PF_3_3'],
  },
];

function PricingGrid({ billingPeriod, firstRender, handleSelectPlan }) {
  const [selectedPlan, setSelectedPlan] = useState(-1);
  const history = useHistory();

  const handleSignUp = () => {
    history.push('/register');
  };

  function handleChangeSelectedPlan(planId) {
    handleSelectPlan();
    if (selectedPlan === planId) {
      setSelectedPlan(-1);
      return;
    }
    setSelectedPlan(planId);
    if (planId < 2) {
      handleSignUp(true);
    }
  }

  return (
    <>
      <div
        className="relative w-full lg:mt-0 flex items-center justify-center flex-wrap gap-x-0 md:gap-x-20"
        role="list">
        {plans.map((plan, index) => (
          <PricingCard
            firstRender={firstRender}
            billingPeriod={billingPeriod}
            {...plan}
            handleChangeSelectedPlan={() => handleChangeSelectedPlan(index)}
          />
        ))}
      </div>
      <div
        className="relative w-full lg:mt-0 flex items-start justify-center flex-wrap gap-x-0 md:gap-x-20 d-none d-md-flex"
        role="list">
        {plans.map((plan) => (
          <PlanFeatures features={plan.features} />
        ))}
      </div>
    </>
  );
}

PricingGrid.propTypes = {
  billingPeriod: PropType.string,
  firstRender: PropType.bool,
  handleSelectPlan: PropType.func,
};

export default PricingGrid;
