import React, { memo, useEffect, useState } from 'react';
import PropType from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PricingCountUp from './pricingCountUp';
import PlanFeatures from './planFeatures';

function BasicPricingCard({
  name,
  priceMonth,
  priceYear,
  description,
  billingPeriod,
  firstRender,
  handleChangeSelectedPlan,
  currency,
}) {
  function PriceAmount() {
    return (
      <PricingCountUp
        price={billingPeriod === 'monthly' ? priceMonth : priceYear}
        startFrom={billingPeriod === 'monthly' ? priceYear : priceMonth}
      />
    );
  }
  function PriceAmountAnimation() {
    switch (billingPeriod) {
      case 'monthly':
        return priceMonth;
      default:
        return priceYear;
    }
  }

  return (
    <>
      <div
        role="listitem"
        className="bg-yellow-200 shadow rounded-xl mt-3 flex relative z-30 w-full md:w-80"
        style={{ minHeight: '350px' }}>
        <div className="w-full p-4 flex flex-col justify-between">
          <p className="m-0 text-center text-4xl font-bold">
            {/* <FormattedMessage id={name} /> */}
            YUGE <br /> mobile <br /> APP
          </p>
          <div className="flex flex-col items-center">
            <p className="leading-10 text-center text-4xl md:mt-0 mt-4 font-bold mb-1">
              {currency}
              {firstRender ? <PriceAmountAnimation /> : <PriceAmount />}/
              <span className="text-2xl">
                {' '}
                <FormattedMessage
                  id={billingPeriod === 'monthly' ? 'MONTH' : 'YEAR'}
                />
              </span>
            </p>
            <p className="text-center font-semibold text-lg mb-4">
              {'{'}
              <FormattedMessage id="EX_BTW" />
              {'}'}
            </p>
            <button
              type="button"
              className="text-lg w-60 text-black font-bold border border-gray-200 bg-gray-50 bg-opacity-80 hover:bg-gray-100 hover:text-black active:bg-blue-600 font-semibold py-2 rounded-full outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              onClick={handleChangeSelectedPlan}>
              <FormattedMessage id="SUBSCRIBE" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

function RecommendedPricingCard({
  name,
  priceMonth,
  priceYear,
  description,
  billingPeriod,
  firstRender,
  handleChangeSelectedPlan,
  currency,
}) {
  function PriceAmount() {
    return (
      <PricingCountUp
        price={billingPeriod === 'monthly' ? priceMonth : priceYear}
        startFrom={billingPeriod === 'monthly' ? priceYear : priceMonth}
      />
    );
  }
  function PriceAmountAnimation() {
    switch (billingPeriod) {
      case 'monthly':
        return priceMonth;
      default:
        return priceYear;
    }
  }
  return (
    <div
      role="listitem"
      className="bg-green-300 shadow rounded-xl mt-3 flex flex-col items-center relative z-30 w-full md:w-80"
      style={{ minHeight: '400px' }}>
      <div className="text-xl bg-green-100 bg-opacity-80 p-2 px-4 rounded-b-xl font-bold mb-2">
        <FormattedMessage id="RECOMMENDED" />
      </div>
      <div className="w-full p-4 flex flex-col items-center justify-between">
        <p className="m-0 text-center text-4xl text-blue-600 font-bold">
          <FormattedMessage id={name} />
        </p>
        <p className="text-base p-3 font-bold">
          <FormattedMessage id={description} />
        </p>
        <p className="leading-10 text-center md:mt-0 mt-3 font-bold leading-6 text-5xl mb-1">
          {currency}
          {firstRender ? <PriceAmountAnimation /> : <PriceAmount />}/
          <span className="text-2xl">
            {' '}
            <FormattedMessage
              id={billingPeriod === 'monthly' ? 'MONTH' : 'YEAR'}
            />
          </span>
        </p>
        <p className="text-center font-semibold text-lg mb-5">
          {'{'}
          <FormattedMessage id="EX_BTW" />
          {'}'}
        </p>
        <p className="text-center font-semibold text-lg mb-5">
          <FormattedMessage id="FREE_TRIAL_PRICING" />
        </p>
        <button
          type="button"
          className="text-lg w-60 text-black font-bold border border-gray-200 bg-green-100 bg-opacity-80 hover:bg-green-100 hover:text-black active:bg-blue-600 font-semibold py-2 rounded-full outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          onClick={handleChangeSelectedPlan}>
          <FormattedMessage id="SUBSCRIBE" />
        </button>
      </div>
    </div>
  );
}

function FlexiblePricingCard({ name, description }) {
  const history = useHistory();
  return (
    <div
      role="listitem"
      className="bg-gray-200 shadow rounded-xl mt-3 flex flex-col justify-between relative z-30 w-full md:w-80"
      style={{ minHeight: '350px' }}>
      <div className="w-full p-4 flex flex-col justify-between items-center">
        <p className="m-0 text-center text-4xl">
          <FormattedMessage id={name} />
        </p>
        <p className="text-base my-4 p-3">
          <FormattedMessage id={description} />
        </p>
        <button
          type="button"
          className="text-lg w-60 text-black font-bold border-2 border-gray-400 bg-gray-200 bg-opacity-80 hover:bg-gray-100 hover:text-black active:bg-blue-600 font-semibold py-2 rounded-full outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          onClick={() => history.push('/contact')}>
          <FormattedMessage id="GET_QUOTE" />
        </button>
      </div>
    </div>
  );
}

function PricingCard(props) {
  const { features } = props;
  const [currency, setCurrency] = useState('$');

  useEffect(() => {
    const interval = setInterval(() => {
      const c = document.body.innerHTML.includes(
        'Login portal to manage everything',
      )
        ? '$'
        : '€';
      setCurrency(c);
    }, 50);
    return () => clearInterval(interval);
  }, []);

  let component = <BasicPricingCard currency={currency} {...props} />;
  if (props.featured)
    component = <RecommendedPricingCard currency={currency} {...props} />;
  if (!props.featured && !props.freeTrial)
    component = <FlexiblePricingCard {...props} />;

  return (
    <div className="w-full md:w-auto">
      {component}
      <div className="d-block d-md-none">
        <PlanFeatures features={features} />
      </div>
    </div>
  );
}

PricingCard.propTypes = {
  name: PropType.string,
  priceMonth: PropType.number,
  priceYear: PropType.number,
  description: PropType.string,
  featured: PropType.bool,
  billingPeriod: PropType.string,
  firstRender: PropType.bool,
  handleChangeSelectedPlan: PropType.func,
  freeTrial: PropType.bool.isRequired,
  features: PropType.array,
};

export default memo(PricingCard);
