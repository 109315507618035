import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ReactPlayer from 'react-player';
import Block from './technology/block';
import ImageColumns from './technology/imageColumns';
import TextBlock from './technology/title';
import PrioritizedMerchantGrid from './technology/prioritizedMerchantGrid';
import FreeTrialButton from './common/freeTrialButton';
import AutomaticModelCreation from './technology/automacticModelCreation';
import CustomArBanner from './technology/customArBanner';
import Referrals from './technology/referrals';
import Hosting from './technology/hosting';
import arrow from '../assets/img/modelCreation/arrow-white.svg';
import { isMobile } from '../utils/device';

const Technology = ({ locale, onMount }) => {
  useEffect(() => {
    onMount('Technology');
  }, []);

  return (
    <div className="page-index">
      <div
        style={{
          backgroundColor: '#0071e3',
          width: '100%',
          height: 60,
          fontSize: '22px',
        }}>
        <a
          href="https://apps.apple.com/us/app/3d-scanner-yuge/id6474502283"
          className="text-white text flex flex-row justify-content-center align-items-center"
          style={{ width: '100%', height: '100%' }}
          target="_blank">
          <FormattedMessage
            id={isMobile() ? 'TOP_RIBBON_TEXT_MOBILE' : 'TOP_RIBBON_TEXT'}
          />
          <img className="ml-3" src={arrow} alt="" />
        </a>
      </div>
      <CustomArBanner />
      <Block
        title={<FormattedMessage id="AUTOMATIC_MODEL_CREATION" />}
        subtitle={<FormattedMessage id="AUTOMATIC_MODEL_CREATION_SUBTITLE" />}>
        <p className="container d-none d-sm-none d-md-block d-lg-block d-xl-block text-xl mt-16 md:w-3/4 text-center text-gray-800 font-semibold md:text-4xl">
          <FormattedMessage
            id="AUTOMATIC_MODEL_CREATION_DESCRIPTION"
            values={{ br: <span /> }}
          />
        </p>
        <p className="container text-left d-block d-sm-block d-md-none d-lg-none d-xl-none text-xl mt-6 mb-8 md:w-3/4 text-gray-800 md:text-4xl">
          <FormattedMessage
            id="AUTOMATIC_MODEL_CREATION_DESCRIPTION"
            values={{ br: <span /> }}
          />
        </p>
        <div className="mt-12">
          <AutomaticModelCreation locale={locale} />
        </div>
      </Block>

      <Block
        title={<FormattedMessage id="VIDEO_SECTION_TITLE" />}
        subtitle={<FormattedMessage id="VIDEO_SECTION_SUBTITLE" />}>
        <p className="container d-none d-sm-none d-md-block d-lg-block d-xl-block text-xl mt-16 md:w-3/4 text-center text-gray-800 font-semibold md:text-4xl">
          <FormattedMessage
            id="VIDEO_SECTION_DESCRIPTION"
            values={{ br: <span /> }}
          />
        </p>
        <p className="container text-left d-block d-sm-block d-md-none d-lg-none d-xl-none text-xl mt-6 mb-8 md:w-3/4 text-gray-800 md:text-4xl">
          <FormattedMessage
            id="VIDEO_SECTION_DESCRIPTION"
            values={{ br: <span /> }}
          />
        </p>
        <div className="container flex flex-row items-center justify-center mt-20 pb-8 beforeAfter">
          <ReactPlayer url="https://youtu.be/Ev4DGlzchBs" />
        </div>
      </Block>

      <Block>
        <div className="container justify-content-center">
          <h1 className="font-semibold text-center text-yellow-400 text-6xl">
            <FormattedMessage id="INDEX_1_HEADER_1" />
          </h1>
        </div>
        <ImageColumns />
      </Block>

      <Referrals />

      <Hosting />

      <TextBlock title={<FormattedMessage id="INDEX_3_HEADER_1" />} />
      <PrioritizedMerchantGrid />
      <div className="flex flex-col items-center mb-10">
        <FreeTrialButton
          classes="free-trial-account text-white mt-6"
          text={<FormattedMessage id="TRY_HERE_BUTTON" />}
        />
      </div>
    </div>
  );
};
Technology.propTypes = {
  locale: PropTypes.string,
};
export default memo(Technology);
