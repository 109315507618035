import React from 'react';
import { Row, Col } from 'react-bootstrap';

const TaxesFees = () => {
  return (
    <Row>
      <Col xs={6}>Taxes and fees</Col>
      <Col xs={6} style={{ textAlign: 'right' }}>
        <strong>$0</strong>
      </Col>
    </Row>
  );
};

export default TaxesFees;
