// import { addLocaleData } from 'react-intl';
// import localeCA from 'react-intl/locale-data/ca';
// import localeEN from 'react-intl/locale-data/en';
// import localeES from 'react-intl/locale-data/es';
// import localeNL from 'react-intl/locale-data/nl';
// import messagesCA from './translations/ca.json';
// import messagesEN from './translations/en.json';
// import messagesES from './translations/es.json';
// import messagesNL from './translations/nl.json';
import i18n from './translations/i18n-yuge-web-app-spa.json';

const languages = [
  { iso: 'en', title: 'Usa' },
  { iso: 'es', title: 'Esp' },
  { iso: 'nl', title: 'Nl' },
];
let defaultLocale = 'en';
if (document.domain.toLowerCase().endsWith('.nl')) defaultLocale = 'nl';
if (document.domain.toLowerCase().endsWith('.es')) defaultLocale = 'es';

let selectedLocale = defaultLocale;

function getMessages(locale) {
  selectedLocale = locale;
  switch (locale) {
    case 'ca':
      return i18n.ca;
    case 'en':
      return i18n.en;
    case 'es':
      return i18n.es;
    case 'nl':
      return i18n.nl;
    default:
      selectedLocale = defaultLocale;
      return i18n.en;
  }
}

export default {
  languages,
  defaultLocale,
  selectedLocale,
  getMessages,
};
