import React, { memo, useState } from 'react';
import FAQSContainer from './pricing/faqsContainer';
import PricingGrid from './pricing/pricingContainer';
import PricingHeader from './pricing/PricingHeader';

const Pricing = () => {
  const [period, setPeriod] = useState('monthly');
  const [firstRender, setFirstRender] = useState(true);
  function handleFirstRender() {
    setFirstRender(false);
  }
  function handleSelectPlan() {
    setFirstRender(true);
  }
  function handlePeriod() {
    handleFirstRender();
    switch (period) {
      case 'monthly':
        handleSelectPlan();
        setPeriod('annually');
        break;
      default:
        setPeriod('monthly');
        break;
    }
  }
  return (
    <div className="page-pricing px-4 md:px-16 md:py-24 py-14">
      <div className="flex flex-col items-center justify-between">
        <PricingHeader period={period} handlePeriod={handlePeriod} />
        <PricingGrid
          billingPeriod={period}
          firstRender={firstRender}
          handleSelectPlan={handleSelectPlan}
        />
        {/* <FreeTrialButton
          classes="free-trial-account text-white mt-16"
          text={<FormattedMessage id="CONTACT_PAGE" />}
        /> */}
      </div>
      <FAQSContainer />
    </div>
  );
};
export default memo(Pricing);

// styles_afterWrapper__24Cdm
// styles_handle__33IZp
