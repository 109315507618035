import React, { memo } from 'react';
import badgeEnSrc from '../../assets/img/Badge_ENG.svg';

const Badges = () => {
  return (
    <div className="mb-2">
      <a
        id="iphone-badge"
        className="badge-button badge-iphone"
        href="https://apps.apple.com/nl/app/yuge-merchant/id6443528979?l=en"
        onClick={() =>
          window.handleOutboundLinkClicks(
            'App Store Buttons',
            'Click',
            'App Store',
          )
        }>
        <span className="hidden">App Store</span>
      </a>
      <a
        className="badge-button badge-android text-decoration-none"
        href="https://play.google.com/store/apps/details?id=com.goyuge.merchant&hl=en&gl=US">
        <img
          lang="en"
          alt="Get it on Google Play"
          src={badgeEnSrc}
          onClick={() =>
            window.handleOutboundLinkClicks(
              'App Store Buttons',
              'Click',
              'Google Play',
            )
          }
        />
      </a>
    </div>
  );
};
export default memo(Badges);
