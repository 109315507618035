import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

const plus = 'https://linearicons.com/free/icon/lnr-plus-circle.svg';
const minus = 'https://linearicons.com/free/icon/lnr-circle-minus.svg';

export default function FAQSItem({ question, answer }) {
  const [show, setShow] = useState(false);
  return (
    <div className="w-full max-w-6xl p-8 bg-gray-200 mt-10 rounded-xl">
      <div className="flex justify-between items-center transition-transform duration-500 easy mb-2">
        <span className="font-bold">
          <FormattedMessage id={question} />
        </span>
        <img
          onClick={() => setShow(!show)}
          className="w-10 h-10 cursor-pointer"
          src={show ? minus : plus}
          alt="switch faq"
        />
      </div>
      <p
        className={
          show ? 'transition-transform' : 'transition-transform hidden'
        }>
        <FormattedMessage id={answer} />
      </p>
    </div>
  );
}
