import { useMemo } from "react";
import { useLocation } from "react-router-dom";

// A custom hook that builds the query string on useLocation to parse
// You can use it: const query = useQuery();
// and then get vars like this: query.get("name")
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default useQuery;
