import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function PlanFeatures({ features }) {
  return (
    <div
      role="listitem"
      className="bg-white my-12 relative z-30 w-full md:w-80 font-semibold p-1">
      {features?.map((feature) => (
        <div
          className="text-base flex gap-2 items-center mt-2"
          key={feature.name}>
          {feature.enabled ? (
            <img src="/img/ok.svg" alt="ok icon" />
          ) : (
            <img src="/img/nok.svg" alt="ok icon" />
          )}
          <a
            href={feature.onclick ? feature.onclick : null}
            className={'text-dark ' + feature.style}>
            <FormattedMessage id={feature.name} />
          </a>
        </div>
      ))}
    </div>
  );
}
