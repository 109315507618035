import React, { memo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import AliceCarousel from 'react-alice-carousel';
import Block from './block';
import ReferralCard from './referralCard';
import 'react-alice-carousel/lib/alice-carousel.css';
import arrow from '../../assets/img/referrals/arrow.svg';

const responsive = {
  0: { items: 1 },
  568: { items: 1 },
  1024: { items: 1 },
};

const referralData = [
  {
    name: 'Maarten',
    position: 'VALUATOIN_POSITION',
    company: 'Nijhuis Meubels',
    valoration: 'R_1_V',
  },
];

const items = referralData.map((referral) => (
  <ReferralCard key={referral.name} {...referral} />
));

const Referrals = () => {
  const Carousel = useRef(null);
  const slidePrev = () => {
    Carousel.current.slidePrev();
  };
  const slideNext = () => {
    Carousel.current.slideNext();
  };
  return (
    <div className="container">
      <Block title={<FormattedMessage id="REFERRALS" />} margin="m-16">
        <div className="flex flex-row justify-center">
          <div
            className="md:w-1/12 cursor-pointer flex justify-start"
            onClick={slidePrev}>
            <img
              className="w-full md:w-1/2 md:ml-3 transform rotate-180"
              src={arrow}
              alt="slider controls"
            />
          </div>
          <div className="w-4/5 md:w-5/6">
            <AliceCarousel
              touchTracking
              disableDotsControls
              disableButtonsControls
              smouseTracking
              items={items}
              responsive={responsive}
              ref={Carousel}
              controlsStrategy="alternate"
            />
          </div>
          <div
            className="md:w-1/12 cursor-pointer flex justify-end"
            onClick={slideNext}>
            <img
              className="w-full md:w-1/2 md:mr-3"
              src={arrow}
              alt="slider controls"
            />
          </div>
        </div>
      </Block>
    </div>
  );
};
export default memo(Referrals);
