import React, { Component } from 'react';
import PropType from 'prop-types';

const TrustBox = ({ trustBoxRef }) => (
  <div
    ref={trustBoxRef} // We need a reference to this element to load the TrustBox in componentDidMount.
    className="trustpilot-widget" // Renamed this to className.
    data-locale="en-US"
    data-template-id="56278e9abfbbba0bdcd568bc"
    data-businessunit-id="632b7675d9c8186defb01998"
    data-style-height="52px"
    data-style-width="100%">
    <a
      href="https://www.trustpilot.com/review/yuge.nl"
      target="_blank"
      rel="noopener noreferrer">
      Trustpilot
    </a>
  </div>
);

class TrustPilotWidget extends Component {
  constructor(props) {
    super(props);
    this.trustBoxRef = React.createRef();
  }

  componentDidMount() {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
    }
  }

  render() {
    return <TrustBox trustBoxRef={this.trustBoxRef} />;
  }
}

TrustBox.propTypes = {
  trustBoxRef: PropType.node,
};

export default TrustPilotWidget;
