const baseURLPROD = 'https://api.goyuge.com';
const baseURLDEV = 'https://dev.api.goyuge.com';

// Adding live parameter in global place for the app
let live = false;
if (
  window.location.href.includes('https://www.goyuge.com') ||
  window.location.href.includes('https://goyuge.com') ||
  window.location.href.includes('https://www.yuge.es') ||
  window.location.href.includes('https://www.yuge.nl') ||
  window.location.href.includes('https://yuge.es') ||
  window.location.href.includes('https://yuge.nl') ||
  window.location.href.includes('https://shop.goyuge.com')
) {
  live = true;
}

export const baseURL = live ? baseURLPROD : baseURLDEV;

export const LIVE = live;

export const MERCHANT_STAGE_URL = live
  ? 'https://merchant.goyuge.com'
  : 'https://dev-merchant-tool.goyuge.com/';

// User
export const registerMerchantByEmail = `${baseURL}/users/register_by_email_with_merchant_name`;
export const registerUserByEmail = `${baseURL}/users/register_by_email`;
export const confirmRegisterUserByEmail = `${baseURL}/users/confirm_register_by_email`;
export const loginUserByEmail = `${baseURL}/token`;

export const searchPlusPagination = `${baseURL}/merchants/search-plus-pagination-params`;
