import { LIVE } from '../api/yugeAPI2';

const devProduct = {
  id: '65ac17787e85dbf1c1783384',
  URLWebp:
    'https://yuge-images-product.goyuge.com/extraction/0df237ba362d42708ce81fd474f9ed33_extraction.webp?Expires=33241777015&Signature=idUN22hAAO~8~qkd~k7po7TVuJTgI1a1QdXUtAMdI2jEHCVEjKOiixcxQqlKLsGf0vov5ugEcXHJlWfQ554dmNl7HchsAPEkdNWkUnyv~dcUgSI6YbFSLJ0~36fT3zsWgi95BK21jX3CLfMPKZU8CvfggIMu2XJ-wL2wFGVTCPLa779k0D9E6LnlY~MHgvaAQnIt3pZ6N63suHe6Tmjz6iV8qZUuuNAd7XPaCPxsbgvnEznSgmWc-Ta9M5sTw4A5uF5DYWR5zxE8Y9bmabA0-pNLeXZT-jmRWN~VVIVP5sjtE-K~bSJapgspwmLbNgyXEBP5EklpeHOVa9SiRxOZ-A__&Key-Pair-Id=APKAJFRT5R5NZTB74DJA',
};

const prodProduct = {
  id: '65ac17787e85dbf1c1783384',
  URLWebp:
    'https://yuge-images-product.goyuge.com/extraction/0df237ba362d42708ce81fd474f9ed33_extraction.webp?Expires=33241777015&Signature=idUN22hAAO~8~qkd~k7po7TVuJTgI1a1QdXUtAMdI2jEHCVEjKOiixcxQqlKLsGf0vov5ugEcXHJlWfQ554dmNl7HchsAPEkdNWkUnyv~dcUgSI6YbFSLJ0~36fT3zsWgi95BK21jX3CLfMPKZU8CvfggIMu2XJ-wL2wFGVTCPLa779k0D9E6LnlY~MHgvaAQnIt3pZ6N63suHe6Tmjz6iV8qZUuuNAd7XPaCPxsbgvnEznSgmWc-Ta9M5sTw4A5uF5DYWR5zxE8Y9bmabA0-pNLeXZT-jmRWN~VVIVP5sjtE-K~bSJapgspwmLbNgyXEBP5EklpeHOVa9SiRxOZ-A__&Key-Pair-Id=APKAJFRT5R5NZTB74DJA',
};

export const getCustomArBannerProduct = () => {
  return LIVE ? prodProduct : devProduct;
};
