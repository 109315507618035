import React from 'react';

function BeforeAfterSliderComponent() {
  return (
    <iframe
      title="beforeAfter"
      frameBorder="0"
      className="juxtapose"
      width="100%"
      height="100%"
      src="/before-after/index.html?uid=26b126cc-3845-11ed-b5bc-6595d9b17862"
    />
  );
}

export default BeforeAfterSliderComponent;
