import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import ScrollAnimation from 'react-animate-on-scroll';
import Block from './block';
import 'react-alice-carousel/lib/alice-carousel.css';
import TextImageSection from '../common/textImageSection';

import image1 from '../../assets/img/hosting/image1.png';
import image2 from '../../assets/img/hosting/image2.png';
import image3 from '../../assets/img/hosting/image3.jpeg';
import image4 from '../../assets/img/hosting/image4.png';
import loopMask from '../../assets/img/hosting/loop-mask.svg';
import loopMaskMobile from '../../assets/img/hosting/loop-mask-mobile.svg';

const Hosting = () => {
  return (
    <div style={{ backgroundColor: '#f2f2f2' }}>
      <div className="container px-0 pb-8 mt-6">
        <Block title={<FormattedMessage id="HOSTING" />}>
          <div className="container px-0 pb-8 mt-6" />
          <h3 className="px-6 md:px-0 text-4xl font-semibold text-black">
            <FormattedMessage id="HOSTING_00" />
          </h3>
          <h4 className="px-6 md:px-0 text-4xl font-semibold text-gray-400">
            <FormattedMessage id="HOSTING_01" />
          </h4>
          <h4 className="px-6 md:px-0 text-4xl font-semibold text-gray-400">
            <FormattedMessage id="HOSTING_02" />
          </h4>
          <div className="pt-2 pb-2 md:pt-8 md:pb-8" />
          <div className="container px-0 pb-0 mt-6" />
          <TextImageSection src={image1} clssName="md:items-end">
            <ScrollAnimation
              animateOnce
              animateIn="animate__fadeIn"
              delay={800}>
              <img
                id="hosting-market"
                className="opacity-0 md:opacity-100"
                src={loopMask}
                alt="loopmask"
              />
            </ScrollAnimation>
            <ScrollAnimation
              animateOnce
              animateIn="animate__fadeIn"
              delay={800}>
              <img
                id="hosting-market-mobile"
                className="opacity-100 md:opacity-0 w-64"
                src={loopMaskMobile}
                alt="loopmask"
              />
            </ScrollAnimation>
            <p className="text-xl px-6 md:px-0">
              <FormattedMessage id="HOSTING_1" />
            </p>
          </TextImageSection>
          <TextImageSection
            link="https://www.nijhuis-ar.nl/"
            inverted
            src={image2}>
            <p className="text-xl px-6 md:px-0">
              <FormattedMessage id="HOSTING_2" />
            </p>
          </TextImageSection>
        </Block>
      </div>
      <div className="container px-0 pb-8 mt-2">
        <Block title="">
          <h3 className="px-6 md:px-0 text-4xl font-semibold text-black">
            <FormattedMessage id="HOSTING_03" />
          </h3>
          <h4 className="px-6 md:px-0 text-4xl font-semibold text-gray-400">
            <FormattedMessage id="HOSTING_04" />
          </h4>
          <div className="pt-2 pb-2 md:pt-8 md:pb-8" />
          <TextImageSection
            link="https://www.foppele-ar.nl/"
            clssName="md:items-end"
            src={image4}>
            <p className="text-xl px-6 md:px-0">
              <FormattedMessage id="HOSTING_3" />
            </p>
          </TextImageSection>
          <TextImageSection
            link="https://www.foppele-ar.nl/product/6400fe95fb419187fd58acf6"
            clssName="md:items-end"
            inverted
            src={image3}>
            <p className="text-xl px-6 md:px-0">
              <FormattedMessage id="HOSTING_4" />
            </p>
          </TextImageSection>
        </Block>
      </div>
    </div>
  );
};
export default memo(Hosting);
