import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { getPrioritizedMerchants } from '../../services/merchantService';
import http from '../../services/httpService';
import arrow from '../../assets/img/modelCreation/arrow.svg';
import MerchantGrid from '../shop/merchantGrid';
import Loading from '../common/loading';

const PrioritizedMerchantGrid = () => {
  const cancelSource = http.cancelSource();

  const [merchants, setMerchants] = useState([]);
  const [merchantsShown, setMerchantsShown] = useState([]);
  const [loading, setLoading] = useState(true);

  const switchMerchant = () => {
    const shuffled = _(merchants).shuffle().value();
    const tempMerchantsShown = _(shuffled).slice(0, 6).value();
    setMerchantsShown(tempMerchantsShown);
  };

  useEffect(() => {
    switchMerchant();
  }, [merchants]);
  const populateMerchant = () => {
    const config = {
      cancelToken: cancelSource.token,
    };
    getPrioritizedMerchants(36, config)
      .then(({ data: merchants }) => {
        setMerchants(merchants);
      })
      .catch(http.errorCatcher)
      .finally(() => setLoading(false));
  };

  useEffect(populateMerchant, []);

  useEffect(() => {
    return () => {
      cancelSource.cancel('Component unmounted.');
    };
  }, []);

  return (
    <div className="container px-md-0">
      <div className="merchant-grid-ax px-0 md:px-0 mt-10 grid grid-cols-1 gap-10 place-items-center">
        <Loading loading={loading} />
        <MerchantGrid
          merchants={merchantsShown}
          isEmptySearch={false}
          clssName=""
        />
        <a
          href="/shops"
          className="w-full mb-2 flex flex-row justify-center ml-5 md:ml-0 md:justify-end text-yellow-400 font-bold"
          style={{ fontSize: '18px' }}>
          <FormattedMessage id="VIEW_ALL_STORES" />
          <div>
            <img className="ml-3 mr-4" src={arrow} alt="" />
          </div>
        </a>
      </div>
    </div>
  );
};

export default PrioritizedMerchantGrid;
