import React, { memo } from 'react';

const HamburgerButton = ({ onClickHamburger, onMouseOverHamburger }) => {
  return (
    <button
      className="hamburger hamburger--collapse"
      type="button"
      style={{ padding: 0 }}
      onClick={(e) => onClickHamburger(e.currentTarget.classList)}
      onMouseOver={onMouseOverHamburger}>
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </button>
  );
};

export default memo(HamburgerButton);
